import React, { useEffect, useRef } from "react";
import { Box } from "@material-ui/core/";
import useStyles from "./styles";
import AnalysisLunaChat from "./analysisLunaChat";
import ProgressBar from "./progressBar";

const AnalysisLensProgress = ({
  startDateTime,
  totalFiles,
  status,
  uniqueFiles,
}) => {
  const classes = useStyles();
  const ref = useRef();

  const batchFiles = 10;
  const processingTimePerBatch = 10;
  // 0 (Pending), 1 (Completed), o 2 (Failed).

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, []);

  return (
    <Box className={classes.progressAnalysisContainer} ref={ref}>
      <Box className={classes.chatLensIconContainerView}>
        <AnalysisLunaChat />
      </Box>
      <ProgressBar
        startDateTime={startDateTime}
        totalFiles={totalFiles}
        batchFiles={batchFiles}
        processingTimePerBatch={processingTimePerBatch}
        status={status}
        view={true}
        uniqueFiles={uniqueFiles}
      />
    </Box>
  );
};

export default AnalysisLensProgress;
