import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ImageIcon from "@material-ui/icons/Image";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";

import useStyles from "./styles";

const DialogOpenImage = ({ open, handleCancel, image, title }) => {
  const classes = useStyles();

  return (
    <Dialog
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleCancel();
        }
      }}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <>
        <MuiDialogTitle disableTypography className={classes.imageDialogTitle}>
          <ImageIcon />
          <Typography variant="h6">{title}</Typography>
        </MuiDialogTitle>
        <MuiDialogContent>
          <Box className={classes.imageDialogContainer}>
            <img
              src={image.url}
              alt={`Grid item #${image.id}`}
              className={classes.image}
            />
          </Box>
        </MuiDialogContent>
        <MuiDialogActions>
          <Button
            onClick={handleCancel}
            className={classes.buttonConfirmSuccess}
            color="primary"
            variant="contained"
            size="large"
            fullWidth
          >
            Close
          </Button>
        </MuiDialogActions>
      </>
    </Dialog>
  );
};

export default DialogOpenImage;
