import React, { useEffect, useRef, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import BadgeIcon from "./badgeIcon";
import MuiAccordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ImageGallery from "./gallery/imageGallery";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Box from "@material-ui/core/Box";

const Accordion = withStyles({
  root: {
    "&$expanded": {
      margin: "auto",
    },
    position: "unset",
    boxShadow: "none",
    margin: "16px 0",
    "&:before": {
      display: "none",
      border: "none",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  content: {
    alignItems: "center",
  },
  expandIcon: {
    marginTop: 6,
    marginBottom: 0,
    alignItems: "center",
  },
})(MuiAccordionSummary);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "&$expanded": {
      margin: "auto",
    },
  },
  defaultBorder: {
    border: "1px solid #ECECEC",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  expanded: {
    "& .collapsIconWrapper": {
      display: "none",
      transform: "none",
      marginTop: "20",
    },
    "& .expandIconWrapper": {
      display: "block",
    },
  },
  contracted: {
    "& .collapsIconWrapper": {
      display: "block",
    },
    "& .expandIconWrapper": {
      display: "none",
      transform: "none",
    },
  },
  hideBorder: {
    "&.MuiExpansionPanel-root:before": {
      display: "none",
    },
  },
  buttonMediaFilled: {
    color: "white",
    textTransform: "none",
    backgroundColor: "#4F98BC",
    border: "0.75px solid #4F98BC",
    borderRadius: "4px",
    padding: "6px 34px 6px 34px",
    minWidth: "0",
    margin: "8px 0px",
    alignSelf: "flex-end",
    "& .MuiButton-label": {
      padding: "0",
    },
    boxShadow:
      "0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#4488A3",
    },
  },
  buttonSelectAll: {
    color: "#4F98BC",
    textTransform: "none",
    border: "1px solid #4F98BC",
    borderRadius: "4px",
    padding: "2px 16px 0px 16px",
    minWidth: "0",
    backgroundColor: "#EDF5F8",
    "& .MuiButton-label": {
      padding: "0",
    },
  },
  categoryContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginRight: "20px",
  },
  categoryDescription: {
    borderRadius: "4px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "8px",
  },
  categoryDetails: {
    margin: "0px 16px 16px 16px",
    borderRadius: "4px",
    backgroundColor: "#F8F8F8",
  },
  categoryGalleryWrapper: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    height: "100%",
    overflow: "hidden",
  },
  categoryGalleryWrapperVerification: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    height: "100%",
    overflow: "hidden",
  },
  galleryHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "12px 0px 16px 0px",
  },
  galleryHeaderOptions: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "16px",
  },
}));

const CustomExpandIcon = ({ expanded }) => {
  const classes = useStyles();
  return (
    <Box className={expanded ? classes.expanded : classes.contracted}>
      <Box className="expandIconWrapper">
        <ExpandLessIcon style={{ margin: "0px" }} />
      </Box>
      <Box className="collapsIconWrapper">
        <ChevronRightIcon />
      </Box>
    </Box>
  );
};

const AnalysisCategoriesAccordionTab = ({ totalMedia, analysisMedia }) => {
  const classes = useStyles();
  const refs = useRef([]);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (expanded !== false && refs.current[expanded]) {
      refs.current[expanded].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [expanded]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: "8px",
        }}
      >
        <Typography variant="h5">Selected Categories and Media</Typography>
        {totalMedia > 0 && (
          <Typography variant="h5" style={{ color: "#4F98BC" }}>
            {`${totalMedia} Files`}
          </Typography>
        )}
      </Box>
      <Box className={classes.root}>
        {analysisMedia?.map((category) => {
          return (
            <Accordion
              key={category.luna_lens_category?.id}
              elevation={0}
              expanded={expanded === category.luna_lens_category?.id}
              onChange={handleChange(category.luna_lens_category?.id)}
              ref={(el) => (refs.current[category.luna_lens_category?.id] = el)}
              TransitionProps={{ unmountOnExit: true }}
              style={{
                backgroundColor: "#F8F8F8",
                border: "0px solid #ECECEC",
              }}
            >
              <AccordionSummary
                expandIcon={
                  <CustomExpandIcon
                    expanded={expanded === category.luna_lens_category?.id}
                    sx={{ margin: "20 28px" }}
                  />
                }
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Box className={classes.categoryContainer}>
                  <Box className={classes.categoryDescription}>
                    <BadgeIcon
                      iconType={category.luna_lens_category?.icon}
                      color={category.luna_lens_category?.icon_color}
                      backgroundColor={
                        category.luna_lens_category?.background_color
                      }
                      background={category.luna_lens_category?.background_color}
                    />
                    <Typography
                      variant="body1"
                      className={classes.categoryText}
                    >
                      {category.luna_lens_category?.label}
                    </Typography>
                  </Box>
                  <Typography variant="body2">
                    {`${category.luna_lens_category_media?.length} Files`}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails className={classes.categoryDetails}>
                {category.luna_lens_category?.value !==
                "service_verification" ? (
                  <Box className={classes.categoryGalleryWrapper}>
                    <>
                      <ImageGallery
                        imageUrls={category?.luna_lens_category_media?.map(
                          (item) => ({
                            id: item.file.id,
                            url: item.file.file_url,
                            mime_type: item.file.mime_type,
                          })
                        )}
                        numberImages={28}
                        viewOnly={true}
                        category={category.luna_lens_category?.value}
                        title={category.luna_lens_category?.label}
                      />
                    </>
                  </Box>
                ) : (
                  <Box className={classes.categoryGalleryWrapperVerification}>
                    <>
                      <Typography variant="h5" style={{ marginBottom: "16px" }}>
                        BEFORE
                      </Typography>
                      <ImageGallery
                        imageUrls={category?.luna_lens_category_media
                          ?.filter((item) => item.verification === "before")
                          ?.map((item) => ({
                            id: item.file.id,
                            url: item.file.file_url,
                            mime_type: item.file.mime_type,
                          }))}
                        numberImages={21}
                        viewOnly={true}
                        category={category.luna_lens_category?.value}
                        verification="before"
                      />
                      <Typography
                        variant="h5"
                        style={{ marginBottom: "16px", marginTop: "16px" }}
                      >
                        AFTER
                      </Typography>
                      <ImageGallery
                        imageUrls={category?.luna_lens_category_media
                          ?.filter((item) => item.verification === "after")
                          ?.map((item) => ({
                            id: item.file.id,
                            url: item.file.file_url,
                            mime_type: item.file.mime_type,
                          }))}
                        numberImages={21}
                        viewOnly={true}
                        category={category.luna_lens_category?.value}
                        verification="after"
                      />
                    </>
                  </Box>
                )}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>
    </>
  );
};

export default AnalysisCategoriesAccordionTab;
