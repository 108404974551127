import React from "react";
import { withRouter } from "react-router-dom";
import { Box, Typography } from "@material-ui/core/";
import Button from "@material-ui/core/Button";
import HistoryOptions from "./historyOptions";
import AnalysisLunaChat from "components/common/LunaLens/analysisLunaChat";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Grid,
} from "@material-ui/core";
import moment from "moment";
import CategoryTagList from "./categoryTagList";
import useStyles from "./styles";
import AnalysisStatusBadge from "./analysisStatusBadge";
import ProgressBar from "./progressBar";
import {
  usePageHeaderDispatch,
  usePageHeaderState,
} from "contexts/pageHeaderContext";

const batchFiles = 10;
const processingTimePerBatch = 10; //seconds

const AnalysisStatusResume = ({
  analysis,
  onCardClick,
  handleOptionClick,
  history,
}) => {
  const classes = useStyles();

  const { pageHeaderTabs } = usePageHeaderState();
  const dispatchPageHeader = usePageHeaderDispatch();

  const handleCardClick = (data) => {
    onCardClick(data);
  };

  const prepareAnalysis = analysis.luna_lens_workticket_analysis.find(
    (item) => {
      return item.saved;
    }
  );
  const filterCategories =
    prepareAnalysis?.luna_lens_analysis_categories?.filter((item) => {
      return item.luna_lens_analysis_categories_trade.length > 0;
    });
  const savedAnalysis = {
    ...prepareAnalysis,
    luna_lens_analysis_categories: filterCategories,
  };

  const unsavedAnalysis = analysis.luna_lens_analysis_media;

  const redirectTab = (data) => {
    const tabId = new Date().valueOf();
    const redirectTab = `/lens-analysis/${data.workticket_id}/${
      data.workticket_number
    }/${data.id}/${"new"}`;
    pageHeaderTabs.push({
      id: tabId,
      label: `${data.workticket_number}`,
      link: redirectTab,
      hasClose: true,
      parent: true,
    });
    dispatchPageHeader({
      type: "SET_PAGE_TAB",
      pageHeaderTabs: [...pageHeaderTabs],
    });
    dispatchPageHeader({ type: "SET_CURRENT_TAB", currentTab: tabId });
    history.push(redirectTab);
  };

  return (
    <Box className={classes.statusResumeContainer}>
      <Box className={classes.statusResumeHeaderContainer}>
        <Typography variant="body1" className={classes.cardWTTitle}>
          {analysis.name}
        </Typography>
        <Box className={classes.statusResumeHeaderOptionsContainer}>
          <AnalysisStatusBadge status={analysis.status} />
          <Typography variant="body1">
            {moment(analysis.created_date).format("DD/MM/YY")}
          </Typography>
          <HistoryOptions
            onOptionClick={handleOptionClick}
            onCardClick={handleCardClick}
            data={analysis}
            status={analysis.status}
          />
        </Box>
      </Box>
      <Box
        className={classes.statusResumeDetailContainer}
        style={{ paddingBottom: analysis.status === 1 ? "0px" : "12px" }}
      >
        <Box className={classes.statusResumeDetailSummaryContainer}>
          <Box className={classes.statusResumeDetailResumeContainer}>
            <Typography
              variant="body1"
              className={classes.cardWTTitle}
              style={{ fontSize: "14px" }}
            >
              {parseInt(analysis.status) === 0
                ? unsavedAnalysis[0]?.luna_lens_category?.label
                : savedAnalysis.luna_lens_analysis_categories[0]
                    ?.luna_lens_category?.label}
            </Typography>
            <CategoryTagList
              categories={
                parseInt(analysis.status) === 0
                  ? unsavedAnalysis
                  : savedAnalysis.luna_lens_analysis_categories
              }
              hideLabel={true}
            />
          </Box>
          <Typography
            variant="body1"
            className={classes.cardWTTitle}
            style={{ fontSize: "14px" }}
          >
            {`${analysis.total_media} Files`}
          </Typography>
        </Box>
        {parseInt(analysis.status) === 1 && (
          <List disablePadding>
            <ListItem>
              <Box className={classes.listItemContainer}>
                <ListItemIcon className={classes.listItemIcon}>
                  <FiberManualRecordIcon className={classes.listRecordIcon} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <>
                      <Typography
                        component="span"
                        variant="body1"
                        style={{ fontSize: "14px" }}
                      >
                        {savedAnalysis.luna_lens_analysis_categories[0]
                          .luna_lens_analysis_categories_trade[0]?.title &&
                          `${savedAnalysis.luna_lens_analysis_categories[0]?.luna_lens_analysis_categories_trade[0]?.title}: `}
                      </Typography>
                      <Typography
                        component="span"
                        variant="body1"
                        style={{ fontSize: "14px" }}
                      >
                        {savedAnalysis.luna_lens_analysis_categories[0]
                          .luna_lens_analysis_categories_trade[0]?.description
                          .length > 180
                          ? savedAnalysis.luna_lens_analysis_categories[0]?.luna_lens_analysis_categories_trade[0]?.description.slice(
                              0,
                              180
                            ) + "..."
                          : savedAnalysis.luna_lens_analysis_categories[0]
                              ?.luna_lens_analysis_categories_trade[0]
                              ?.description}
                      </Typography>
                    </>
                  }
                />
              </Box>
            </ListItem>
          </List>
        )}
      </Box>
      {parseInt(analysis.status) !== 1 && (
        <Box className={classes.statusResumeFooterContainer}>
          <Grid container spacing={2}>
            <Grid item>
              <AnalysisLunaChat width={30} height={24} size="medium" />
            </Grid>
            <Grid item xs>
              <ProgressBar
                startDateTime={analysis.start}
                totalFiles={analysis.total_media}
                uniqueFiles={analysis.total_unique_media}
                batchFiles={batchFiles}
                processingTimePerBatch={processingTimePerBatch}
                status={parseInt(analysis.status)}
              />
            </Grid>
            <Grid item>
              <Button
                className={classes.buttonOpenReport}
                onClick={() => {
                  redirectTab(analysis);
                }}
              >
                Open Report
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default withRouter(AnalysisStatusResume);
